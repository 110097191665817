.hero {
    width: 100%;
    float: left;
    overflow: hidden;
    position: relative;
}
.hero .opacity {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 99999;
    width: 100%;
    height: 100%;
    top: 0;
}
.hero img {
    width: 100%;
}
.hero .title {
    font-family: cursive;
    position: absolute;
    z-index: 99999;
    bottom: 5%;
    right: 14%;
    width: 600px;
    letter-spacing: 0.02em;
    text-align: right;
    font-size: 64px;
    font-weight: bold;
    color: #ffffff;
    line-height: 64px;
    text-transform: uppercase;
}
.about {
    width: 100%;
    float: left;
    margin: 150px 0;
}
.about .box {
    width: 85%;
    margin: 0 auto;
}
.about .box .text {
    width: 45%;
}
.about .box p {
    padding: 20px 0;
    font-size: 16px;
}
.about .box p:nth-of-type(5) {
    width: 100%;
}
.about .box .img {
    width: 50%;
    float: right;
}
.about .box .img img {
    width: 95%;
    float: right;
}
.counter {
    width: 100%;
    float: left;
    margin: 0 auto;
    position: relative;
}
.counter img {
    width: 100%;
    height: auto;
}
.counter .opacity {
    position: absolute;
    z-index: 9999;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}
.counter .counter-content {
    position: absolute;
    z-index: 99999999;
    top: 0;
    width: 100%;
}
.counter .box {
    width: 85%;
    margin: 0 auto;
    margin-top: 200px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.counter .box .counter-el {
    width: 33.3%;
}
.counter .box .counter-el .number {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
}
.counter .box .counter-el .number span {
    font-size: 60px;
}
.counter .box .counter-el .number span,
.counter .box .counter-el h5 {
    font-family: "Playfair Display", serif;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}
.products .box .img {
    float: left;
}
.products .box .text {
    float: right;
    width: 45%;
}
.galleria {
    width: 100%;
    float: left;
}
.galleria .owl-carousel {
    margin-bottom: 150px;
    max-width: 1400px;
    margin: 0 auto;
}
.galleria .owl-carousel .item a {
    font-size: 16px;
    line-height: 21px;
    color: #3f2803;
    font-weight: 600;
    padding: 10px 0px 0px 0px;
    display: block;
    font-family: "Open Sans";
    -webkit-transition: color 1s;
    -moz-transition: color 1s;
    -o-transition: color 1s;
    transition: color 1s;
    text-align: center;
    text-decoration: none;
}
.owl-theme .owl-dots .owl-dot span {
    background: #a0d445;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #3f2803;
}
.owl-theme .owl-dots .owl-dot {
    margin-top: 20px;
}
.galleria .owl-carousel .item h6 {
    font-size: 11px;
    line-height: 16px;
    color: #a0d445;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 5px;
    text-align: center;
}
.galleria .owl-carousel .item h6:nth-of-type(2) {
    color: #3f2803;
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    font-family: "Open Sans";
    text-transform: lowercase;
}
.galleria .owl-carousel .item a:hover {
    color: #a0d445;
    cursor: pointer;
    -webkit-transition: color 1s;
    -moz-transition: color 1s;
    -o-transition: color 1s;
    transition: color 1s;
}
.galleria .owl-carousel .item .img a {
    width: 150px;
    line-height: 1.8;
    letter-spacing: 1.1;
    border: 0;
    outline: 0;
    left: 50%;
    position: absolute;
    cursor: pointer;
    top: -50px;
    font-size: 15px;
    background: #3f2803;
    margin-left: -75px;
    font-family: inherit;
    color: #fff;
    -webkit-transition: background 1s, top 1s;
    -moz-transition: background 1s, top 1s;
    -o-transition: background 1s, top 1s;
    transition: background 1s, top 1s;
}
.galleria .owl-carousel .item .img a {
    padding: 0;
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    line-height: 1.8;
}

.galleria .owl-carousel .item .img {
    overflow: hidden;
    cursor: pointer;
}
.galleria .owl-carousel .item:hover a {
    top: 105px;
    -webkit-transition: top 1s;
    -moz-transition: top 1s;
    -o-transition: top 1s;
    transition: top 1s;
}
.galleria .owl-carousel .item .img a:hover {
    background: #a0d445;
    -webkit-transition: background 1s;
    -moz-transition: background 1s;
    -o-transition: background 1s;
    transition: background 1s;
}
.galleria .owl-theme .owl-nav {
    display: none;
}
/* dialog */
.ui-dialog {
    top: 40% !important;
    margin-top: 0;
    z-index: 99999999 !important;
    text-align: center;
    max-width: 360px !important;
}
.ui-widget-overlay {
    background: #afd866;
}
.ui-dialog .ui-dialog-titlebar {
    padding: 0;
    visibility: hidden;
}
.ui-dialog-content p {
    font-size: 15px;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

.ui-dialog .btn {
    float: right;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    background: transparent;
}
.ui-dialog .btn i {
    padding: 5px;
    font-size: 32px;
}

.ui-dialog .btn.active.focus,
.ui-dialog .btn.active:focus,
.ui-dialog .btn.focus,
.ui-dialog .btn:active.focus,
.ui-dialog .btn:active:focus,
.ui-dialog .btn:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    outline-color: #fff;
}

.ui-dialog-titlebar.ui-widget-header.ui-corner-all.ui-helper-clearfix {
    display: none;
}

.ui-dialog h1 {
    margin-top: 60px;
}

.ui-widget-content {
    border: 4px solid #b7d971;
}

@media (min-width: 1920px) {
    .about .box {
        max-width: 1500px;
    }
    .counter {
        height: 500px;
        overflow: hidden;
    }
}
@media (max-width: 1000px) {
    .about {
        margin: 80px 0;
    }
    .about .box .text {
        width: 100%;
        float: none;
    }
    .products .box .img {
        margin-bottom: 20px;
        width: 50%;
        float: right;
    }
    .counter .box {
        margin-top: 70px;
    }
}
@media (max-width: 678px) {
    .hero {
        height: 250px;
    }
    .hero img {
        height: 100%;
    }
    .about {
        margin: 80px 0;
    }
    .about .box .img img,
    .about .box .text,
    .about .box .img {
        width: 100%;
    }
    .about h2 {
        padding-bottom: 0;
    }
    .about .box .img img {
        margin-bottom: 30px;
    }
    .counter {
        height: 200px;
    }
    .counter .box {
        width: 100%;
        margin: 0 auto;
    }
    .counter img {
        height: 100%;
    }
    .counter .box .counter-el .number span {
        font-size: 35px;
    }
    .counter .box .counter-el h5 {
        font-size: 12px;
    }
    .counter .box .counter-el {
        margin-top: 55px;
    }
    .galleria .owl-carousel .item a,
    .galleria .owl-carousel .item h6 {
        text-align: center;
    }
    .ui-dialog {
        top: 30% !important;
    }
    .ui-dialog-content p {
        font-size: 15px;
    }
}
